import {RecipeForm} from "./RecipeModel";
import {FormEvent, useEffect, useRef, useState} from "react";
import {Autocomplete, Button, Divider, Grid, IconButton, TextareaAutosize, TextField, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import '../App.css'

interface Props {
    form: RecipeForm;
    addIngredient: () => void;
    deleteIngredient: (index: number) => void;
    ingredientNames: string[];
    units: string[];
    onSubmit: (e:FormEvent<HTMLFormElement>)=>void;
}

const RecipeFormView = ({form, addIngredient, deleteIngredient, ingredientNames, units, onSubmit}:Props) => {

    const lastRef = useRef<HTMLInputElement>(null);
    const [newIngredientFocus, setNewIngredientFocus] = useState<boolean>(false);

    useEffect(() => {
        if (newIngredientFocus && lastRef.current){
            lastRef.current.focus();
            setNewIngredientFocus(false)
        }
    }, [form, newIngredientFocus]);

    return (
        <form
            onSubmit={onSubmit}
            onKeyDown={(e) => {
                if(e.key === 'Tab') {
                    setNewIngredientFocus(false);
                }
            }}
        >
            <Grid container spacing={2}>

                <Grid item xs={4}>
                    <Typography align={'left'}>
                        Recipe Name
                    </Typography>
                </Grid>
                <Grid item xs={8} display={'flex'} alignItems={'centre'}>
                    <TextField
                        fullWidth
                        style={{marginRight: 'auto'}}
                        id="recipe-name"
                        variant="standard"
                        value={form.name.value}
                        onChange={(e) => form.name.set(e.target.value)}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={4}>
                    <Typography align={'left'}>
                        Servings
                    </Typography>
                </Grid>
                <Grid item xs={8} display={'flex'} alignItems={'centre'}>
                    <TextField
                        fullWidth
                        id="recipe-servings"
                        variant="standard"
                        type="number"
                        placeholder="1"
                        value={form.servings.value}
                        onChange={(e) => form.servings.set(parseInt(e.target.value))}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextareaAutosize
                        style={{width: '100%'}}
                        minRows={3}
                        placeholder={'Method'}
                        value={form.method.value}
                        onChange={(e) => {
                            form.method.set(e.target.value)
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6' align={'left'}>
                        Ingredients
                    </Typography>
                    <Typography variant='body2'>
                        When adding ingredients bear in mind that their names and quantities will become your shopping list.
                        You may may find it better to input 0.5 lime rather than 3 tbsp of lime as it might make more sense
                        when in a shopping list.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                {
                    form.ingredients.map((ingredient, index) => {
                        return (
                            <Grid className={'form-row'} container spacing={2} key={index}>
                                <Grid item xs={7} display={'flex'} alignItems={'centre'}>
                                    <Autocomplete
                                        size={'small'}
                                        fullWidth
                                        id={`ingredient-${index}`}
                                        freeSolo
                                        options={ingredientNames}
                                        renderInput={
                                            (params) => {
                                                return (<TextField
                                                    variant={'standard'}
                                                    {...params}
                                                    label="Ingredient Name"
                                                    inputRef={index === form.ingredients.length - 1 ? lastRef : undefined}
                                                />)
                                            }}
                                        inputValue={ingredient.name.value}
                                        onInputChange={(e, value) => {
                                            console.log('on input change: ', value);
                                            if(value || value === '') {
                                                ingredient.name.set(value)
                                            }

                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        size={'small'}
                                        id={`qty-${index}`}
                                        label="Ingredient Qty"
                                        variant="standard"
                                        type="number"

                                        placeholder="1"
                                        value={ingredient.qty.value}
                                        onChange={(e) => ingredient.qty.set(parseFloat(e.target.value))}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Autocomplete
                                        size={'small'}
                                        fullWidth
                                        id={`ingredient-unit-${index}`}
                                        freeSolo
                                        options={units}
                                        renderInput={
                                            (params) => {
                                                return (<TextField
                                                    {...params}
                                                    variant={'standard'}
                                                    label="Units"
                                                />)
                                            }}
                                        inputValue={ingredient.unit.value}
                                        onInputChange={(e, value) => {
                                            ingredient.unit.set(value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton size={'small'} onClick={(e) => {
                                        deleteIngredient(index);
                                    }
                                    } aria-label="delete">
                                        <DeleteIcon fontSize={'small'}/>
                                    </IconButton>
                                </Grid>
                            </Grid>

                        )
                    })
                }
                </Grid>
                <Grid item xs={12} display={'flex'} alignItems={'centre'}>
                    <Button
                        type="button"
                        onClick={() => {
                            addIngredient();
                            setNewIngredientFocus(true);
                        }}
                    >
                        Add Ingredient
                    </Button>
                    <Button disabled={false} type="submit">
                        Save
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

export default RecipeFormView;