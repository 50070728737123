import { blue, common } from '@mui/material/colors';
import {createTheme} from "@mui/material";

export const headerTheme = createTheme({
    palette: {
        primary: {
            main: blue[900],
            contrastText: common.white
        },
        secondary: {
            main: common.white,
            contrastText: common.white
        }
    },
    components: {
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
                color: "secondary"
            }
        }
    }
})