import {Menu} from "./MenuModel";
import {ButtonGroup, Grid, IconButton, List, ListItem, Typography} from "@mui/material";
import {ChevronRight, KeyboardArrowDown, OpenInNew} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {Ingredient} from "../recipe/RecipeModel";
import '../App.css'

interface Props {
    menu: Menu
    open: boolean
    toggleOpen: () => void
    edit: () => void
    deleteMenu: () => void
    shoppingList: Ingredient[]
}

const MenusView = ({menu, open, toggleOpen, edit, deleteMenu, shoppingList}:Props) => {
    return (
        <Grid className={'dropdown-panel'} item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    <IconButton onClick={(e) => {
                        toggleOpen()
                    }}
                                aria-label="open">
                        {!open && <ChevronRight/> }
                        {open && <KeyboardArrowDown/> }
                    </IconButton>
                </Grid>
                <Grid item xs={9}>
                    <Typography className={'dropdown-title'} align={'left'} fontWeight={'bold'}>{menu.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <ButtonGroup>
                        <IconButton size={'small'} onClick={(e) => {
                            deleteMenu()
                        }
                        } aria-label="delete">
                            <DeleteIcon fontSize={'small'}/>
                        </IconButton>

                        <IconButton onClick={(e) => {
                            edit()
                        }}
                                    aria-label="edit">
                            <EditIcon/>
                        </IconButton>
                    </ButtonGroup>
                </Grid>
            </Grid>
            {open &&
                <>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <p className={'dish-title'}>Dish</p>
                    </Grid>
                    <Grid item xs={4}>
                        <p className={'dish-title'}>Serving</p>
                    </Grid>
                </Grid>
                <List disablePadding>
                    {
                        menu.dishes.map((dish, index) => {
                            return(
                                <ListItem key={index} disablePadding className={'dish-list'}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            {dish.recipe.name}
                                        </Grid>
                                        <Grid item xs={4}>
                                            {dish.servings}
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            )
                        })
                    }
                </List>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant={'h6'} align={'left'} style={{paddingTop: '12px'}}>
                                Shopping List
                            </Typography>
                        </Grid>
                        <Grid item xs={8} display={'flex'} alignContent={'center'}>
                            Ingredient
                        </Grid>
                        <Grid item xs={2} display={'flex'} alignContent={'center'}>
                            Qty
                        </Grid>
                        <Grid item xs={2} display={'flex'} alignContent={'center'}>
                            Unit
                        </Grid>
                    </Grid>
                    <List>
                    {
                        shoppingList.map((ingredient, index) => {
                            return (
                                <ListItem key={index} className={'shopping-list'}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                            {ingredient.name}
                                            <a className={'cartLink'}
                                               target='tescoTab' href={'https://www.tesco.com/groceries/en-GB/search?query='+ingredient.name}>
                                                <OpenInNew style={{paddingTop: '3px', marginBottom: '-2px'}} fontSize='small' />
                                            </a>
                                        </Grid>
                                        <Grid item xs={2}>
                                            {ingredient.qty}
                                        </Grid>
                                        <Grid item xs={2}>
                                            {ingredient.unit}
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            )
                        })
                    }
                    </List>
                </>
            }
        </Grid>
    )
}

export default MenusView