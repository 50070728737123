import React, {useEffect, useState} from 'react';
import Amplify from 'aws-amplify'
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import awsExports from "./aws-exports";
import {Link, Route, Routes, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Menu as MenuEl,
  MenuItem,
  ThemeProvider,
  Toolbar,
  Typography
} from "@mui/material";

import RecipesView from "./recipe/RecipesView";
import RecipeModel, {AppData, Recipe} from "./recipe/RecipeModel";
import MenusView from "./menu/MenusView";
import MenuModel, {Menu} from "./menu/MenuModel";

import {
  createMenu,
  createRecipe,
  deleteMenu as _deleteMenu,
  deleteRecipe as _deleteRecipe,
  listMenus,
  listRecipes,
  updateMenu,
  updateRecipe
} from "./ApiFacade";
import {getIngredientNames, getUnits} from "./utils";
import {headerTheme} from "./MuiThemes";

Amplify.configure(awsExports);

const mapRecipes = (recipes:Recipe[]) : Map<string, Recipe> => {
  const recipeMap = new Map<string, Recipe>();
  recipes.forEach(recipe => {
    if(recipe.id){
      recipeMap.set(recipe.id, recipe);
    }
  })
  return recipeMap;
}

const loadOrInitialiseAppData = async (): Promise<AppData> => {
  const recipes = await listRecipes();
  const recipeMap = mapRecipes(recipes);
  const menus = await listMenus(recipeMap);
  return {recipes: recipes, menus: menus}
}

const saveRecipe = async (recipe: Recipe) => {
  if(recipe.id){
    return updateRecipe(recipe);
  } else {
    return createRecipe(recipe);
  }
}

const saveMenu = async (menu: Menu, recipes: Recipe[]) => {
  if(menu.id){
    return updateMenu(menu, mapRecipes(recipes));
  } else {
    return createMenu(menu, mapRecipes(recipes));
  }
}

const App = () => {

  const [recipes, setRecipes] = useState<Recipe[]>([])
  const [menus, setMenus] = useState<Menu[]>([])
  const [ingredientNames, setIngredientNames] = useState<string[]>([]);
  const [units, setUnits] = useState<string[]>([]);

  useEffect(() => {
    const appData = loadOrInitialiseAppData();
    appData.then(data => {
      setRecipes(data.recipes);
      setMenus(data.menus);
      setUnits(getUnits(data.recipes) ?? []);
      setIngredientNames(getIngredientNames(data.recipes));
    })
  }, [])

  const saveNewRecipe = async (recipe:Recipe) => {
    try {
      const savedRecipe = await saveRecipe(recipe);
      const newRecipes = [
        ...recipes,
        savedRecipe
      ];
      setRecipes(newRecipes);
      setIngredientNames(getIngredientNames(recipes));
      setUnits(getUnits(recipes));
    } catch (e) {
      console.log('there was an error saving the recipe');
    }
  }

  const updateRecipe = async (index:number, recipe:Recipe) => {
    try {
      const savedRecipe = await saveRecipe(recipe);
      const newRecipes = [...recipes];
      newRecipes.splice(index, 1, savedRecipe)
      setRecipes(newRecipes);
      setIngredientNames(getIngredientNames(recipes));
      setUnits(getUnits(recipes));
    } catch (e) {
      console.log('error updating recipe');
    }
  }

  const deleteRecipeFromMenus = async (recipeToDelete: Recipe) => {
    for(let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      const originalNumberOfRecipes = menu.dishes.length;
      const filteredDishes = menu.dishes.filter( dish => dish.recipe.id !== recipeToDelete.id);
      if(filteredDishes.length !== originalNumberOfRecipes) {
        menu.dishes = filteredDishes;
        await updateMenu(i)(menu)
      }
    }
  }

  const deleteRecipe = async (index:number) => {
    try {
      const recipeToDelete = recipes[index];
      await deleteRecipeFromMenus(recipeToDelete);
      await _deleteRecipe(recipeToDelete);
      recipes.splice(index, 1)
      const newRecipes = [
        ...(recipes),
      ];
      setRecipes(newRecipes);
      setIngredientNames(getIngredientNames(recipes));
      setUnits(getUnits(recipes));
    } catch (e) {
      console.log('error deleting recipe');
    }
  }

  const saveNewMenu = async (menu: Menu) => {
    try {
      const savedMenu = await saveMenu(menu, recipes);
      const newMenus = [
        ...menus,
        savedMenu
      ]
      setMenus(newMenus)
    } catch (e) {
      console.log('error saving menu')
    }
  }

  const updateMenu = (index:number) => async (menu:Menu) => {
    try {
      const savedMenu = await saveMenu(menu, recipes);
      const newMenus = [...menus];
      newMenus.splice(index, 1, savedMenu);
      setMenus(newMenus);
    } catch (e) {
      console.log('there was an error updated a menu')
    }
  }

  const deleteMenu = (index:number) => async () => {
    try {
      const menuToDelete = menus[index];
      await _deleteMenu(menuToDelete);
      const newMenus = [...menus];
      newMenus.splice(index,1);
      setMenus(newMenus);
    } catch (e) {
      console.log('error deleting menu')
    }
  }

  //Header stuff
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorElNav)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const navigate = useNavigate()
  const handleCloseNavMenu = (link?: string | any) => {
    if(link && typeof link === 'string') {
      navigate(link);
    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
      <Authenticator>
        {({ signOut, user }) => (
          <div className="App">
            <Box sx={{ flexGrow: 1 }} className={'header'}>
              <ThemeProvider theme={headerTheme}>
                <AppBar position="static">
                <Toolbar>
                  <RestaurantMenuIcon style={{marginRight: '15px'}} fontSize={'large'} />
                  Recipe Book
                  <IconButton
                      sx={{
                        marginLeft: 'auto',
                        display: {xs: "block", sm: "block", md: "none", lg: "none", xl: "none"}}}
                      onClick={ (e) => { open ? handleCloseNavMenu() : handleOpenNavMenu(e)}} >
                    <MenuIcon />
                  </IconButton>
                  <MenuEl
                      id="basic-menu"
                      anchorEl={anchorElNav}
                      open={open}
                      onClose={handleCloseNavMenu}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                  >
                    <MenuItem>
                      <AccountCircleIcon className={'accountIcon'} />
                      {user.username}
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleCloseNavMenu('/')}
                    }>
                      View Recipes
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleCloseNavMenu('addRecipe')}
                    }>
                      Add Recipe
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleCloseNavMenu('viewMenu')}
                    }>
                      View Menu
                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleCloseNavMenu('addMenu')}
                    }>
                      Add Menu
                    </MenuItem>
                    <MenuItem onClick={() => {signOut()}}>
                      Log out
                    </MenuItem>
                  </MenuEl>

                  <Typography align='left' variant="h6" component="div"
                              sx={{marginLeft: '15px', flexGrow: 1, display: {xs: "none", sm: "none", md: "block", lg: "block", xl: "block"} }}>
                    <Link to="/">View Recipes</Link> {" | "}
                    <Link to="addRecipe">Add Recipe</Link> {" | "}
                    <Link to="viewMenu">View Menu</Link> {" | "}
                    <Link to="addMenu">Add Menu</Link> {" | "}
                  </Typography>

                  <Box
                      sx={{
                        flexGrow: 0,
                        marginRight: 0,
                        marginLeft: 'auto',
                        display: {xs: "none", sm: "none", md: "block", lg: "block", xl: "block"}
                      }}>
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <AccountCircleIcon className={'accountIcon'} />
                      {user.username}
                    </IconButton>
                    <MenuEl
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                    >
                      <MenuItem onClick={() => {signOut()}}>
                        Log out
                      </MenuItem>
                    </MenuEl>
                  </Box>
                </Toolbar>
              </AppBar>
              </ThemeProvider>
            </Box>

            <Grid className={'App-body'} container spacing={2}>
              <Routes>
                <Route path="/" element={
                  <RecipesView recipes={recipes} deleteRecipe={deleteRecipe} saveRecipe={updateRecipe} ingredientNames={ingredientNames} units={units} />
                } />
                <Route path="addRecipe" element={<RecipeModel ingredientsNames={ingredientNames} units={units} onSubmit={saveNewRecipe} />} />
                <Route path="viewMenu" element={<MenusView menus={menus} recipes={recipes} saveMenu={updateMenu} deleteMenu={deleteMenu} />} />
                <Route path="addMenu" element={<MenuModel recipes={recipes} onSubmit={saveNewMenu} />} />
              </Routes>
            </Grid>
          </div>
        )}
      </Authenticator>
  )
}

export default App